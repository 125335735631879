import React from 'react'

import { Spacer } from 'ethos-design-system'

import { useTrustageLandingPage } from '@/hooks/features/useTrustageLandingPage'

import { Banner } from './Banner'
import styles from './HighlightNumbers.module.scss'

export interface HighlightNumbersContentProps {
  highlightNumbers?: NumberHighlight[]
  bannerTitle?: string
  bannerDescription?: string
  bannerButtonText?: string
  bannerButtonUrl?: string
  bannerImage?: string
  backgroundImage?: string
  approvedPolicies?: number
}
interface NumberHighlight {
  number?: string
  greenBlob?: boolean
  numberAppend?: string
  text?: string
}

export const HighlightNumbersContent = ({
  highlightNumbers,
  approvedPolicies,
}: HighlightNumbersContentProps) => {
  let policiesApprovedCount = approvedPolicies
  if (highlightNumbers && highlightNumbers.some((entry) => !entry.number)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    policiesApprovedCount = approvedPolicies
  }
  return (
    <div className="flex flex-col">
      <div
        className={[
          styles.highlightNumbersWrapper,
          'flex items-center justify-center gap-10 bg-blue-50',
        ].join(' ')}
      >
        {highlightNumbers?.map((number, index) => (
          <div
            key={`number_${number.text}_${index}`}
            className="items-center justify-center text-center"
          >
            <div
              className={[
                styles.highlightedNumber,
                (number.greenBlob || !number.number) && 'pr-5',
              ].join(' ')}
            >
              <div className={styles.highlightedNumberPrepend}>
                {(number.greenBlob || !number.number) && (
                  <div className={styles.greenBlob} />
                )}
              </div>
              {number.number ?? policiesApprovedCount}
              <div className={styles.highlightedNumberAppend}>
                {number.numberAppend}
              </div>
            </div>
            <div className={styles.highlightedText}>{number.text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
interface HighlightedNumbersProps {
  moduleData: HighlightNumbersContentProps
}

const HighlightNumbers = ({ moduleData, ...rest }: HighlightedNumbersProps) => {
  const { isTreatment } = useTrustageLandingPage()

  const banner = (
    <Banner
      bannerTitle={moduleData.bannerTitle}
      bannerDescription={moduleData.bannerDescription}
      bannerButtonText={moduleData.bannerButtonText}
      bannerButtonUrl={moduleData.bannerButtonUrl}
      bannerImage={moduleData.bannerImage}
      backgroundImage={moduleData.backgroundImage}
    />
  )
  return moduleData.highlightNumbers ? (
    <div className="flex flex-col items-center justify-center">
      <HighlightNumbersContent
        highlightNumbers={moduleData.highlightNumbers}
        {...rest}
      />
      {banner}
    </div>
  ) : (
    <div
      className={[
        'flex flex-col items-center justify-center',
        styles.paddingCropper,
      ].join(' ')}
    >
      {!isTreatment && (
        <div className={styles.neverMobile}>
          <Spacer.H48 />
        </div>
      )}
      {banner}
    </div>
  )
}

export default HighlightNumbers
