import React from 'react'

import classNames from 'classnames'
import { Body, Body2, COLORS, CloudinaryImage } from 'ethos-design-system'

import { useTrustageLandingPage } from '@/hooks/features/useTrustageLandingPage'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import northstarStyles from '../styles/northstar.module.scss'
import { HighlightNumbersContentProps } from './HighlightNumbers'
import styles from './HighlightNumbers.module.scss'

export const Banner = ({
  bannerTitle,
  bannerDescription,
  bannerButtonText,
  bannerButtonUrl,
  bannerImage,
  backgroundImage,
}: HighlightNumbersContentProps) => {
  const { isTreatment } = useTrustageLandingPage()

  const handleCtaClicked = (): void => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        ctaLabel: bannerButtonText,
        clickthroughUrl: bannerButtonUrl,
        module: 'HighlightNumbers',
      },
    })
  }

  const bannerDescriptionToShow = isTreatment
    ? 'Eligible policies include a free will & more worth $449, for you AND your spouse'
    : bannerDescription

  return (
    <div className="relative overflow-hidden">
      <div
        className={[styles.whiteCurveOverlay, ''].join('absolute left-0 top-0')}
      >
        <div className={styles.inset} />
      </div>
      <div className="absolute bottom-0 left-0  hidden w-screen md:block">
        <CloudinaryImage
          publicId={
            isTreatment
              ? 'https://res.cloudinary.com/getethos/image/upload/v1732548235/white_vector_fvoamu.png'
              : backgroundImage
          }
          alt={'Background Image'}
          width={[1920, 1920, 1920, 1920]}
          height={[640, 640, 640, 640]}
        />
      </div>
      <div
        className={[
          styles.bannerWrapper,
          'flex h-full w-full flex-col items-center justify-center md:flex-row md:items-end',
          isTreatment ? styles.trustageBannerWrapper : '',
        ].join(' ')}
      >
        <div
          className={[
            'z-1 flex h-full flex-col justify-center',
            styles.bannerContentWrapper,
          ].join(' ')}
        >
          <div className={northstarStyles.northstarTitle}>{bannerTitle}</div>
          <Body.Regular400>{bannerDescriptionToShow}</Body.Regular400>
          <div
            className={classNames(styles.bannerButtonWrapper, {
              [styles.trustageBannerButtonWrapper]: isTreatment,
            })}
          >
            <a
              onClick={handleCtaClicked}
              href={bannerButtonUrl}
              className={styles.bannerButton}
            >
              <Body2.Regular400 color={COLORS.WHITE}>
                {bannerButtonText}
              </Body2.Regular400>
            </a>
          </div>
        </div>
        <div className={styles.bannerImageWrapper}>
          <CloudinaryImage
            className={classNames([styles.bannerImage].join(' '), {
              [styles.trustageBannerImage]: isTreatment,
            })}
            publicId={bannerImage}
            alt="Banner Image"
            width={[543, 543, 543, 543]}
            height={[461, 461, 461, 461]}
          />
        </div>
      </div>
    </div>
  )
}
